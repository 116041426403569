module.exports = {
  Server: {
    CREATED_GAME: 'created game',
    UNANSWERED: 'unanswered',
    ANSWERED: 'answered',
    SCORE_UPDATE: 'score update',
    NEW_QUESTION: 'new question',
    GAME_ENDED: 'game ended',
    PLAYER_LEFT: 'player left',
    CHAT: 'chat',
    PLAYER_JOINED: 'player joined',
    NO_SUCH_GAME: 'no such game',
    USERNAME_ASSIGNED: 'username assigned',
    ROOM_CLOSED: 'room closed',
    GAMES_LIST: 'games list',
    UNANSWERED_QUESTIONS_LIST: 'unanswered questions list',
    WAITING_FOR_ANSWER_FROM_USER: 'waiting for answer from user',
    PLAYER_SET_INACTIVE: 'player set inactive',
    PLAYER_SKIPPED: 'player skipped',
    PLAYER_ANSWERED: 'player answered',
    PLAYER_REACTIVATED: 'player reactivated',
    PLAYER_LIST: 'player list',
    ANSWER_REJECTED: 'answer rejected',
  },
  Client: {
    CREATE_GAME: 'create game',
    JOIN_GAME: 'join game',
    SKIP: 'skip',
    CHAT: 'chat',
  },
};
