const usernames = [
  'Acrallef',
  'Apnalled',
  'AprilHomey',
  'Archift',
  'Atediney',
  'Attractive',
  'Boboundraw',
  'Boninget',
  'Buddientbr',
  'BuffMatterExclusive',
  'Buggyreet',
  'Carecore',
  'Certion',
  'Cessorks',
  'Chasemalead',
  'CheeseSra',
  'Churuser',
  'Cleanzant',
  'ComfyConspiracy',
  'Cosinks',
  'CrashStories',
  'CrazyEye',
  'Dailiesba',
  'DarthCeticNight',
  'Divagoon',
  'Dubyaricamf',
  'Dubyarita',
  'Ducatchab',
  'EatsyouIzPhobic',
  'EssenceReporter',
  'Everchicat',
  'Exegycond',
  'ExoticMaxi',
  'Fashionew',
  'Fighteritaz',
  'Fishitly',
  'Fixtudy',
  'Fourianity',
  'Fruitelec',
  'FuzzyQuote',
  'Gemainn',
  'GinoJournal',
  'Goosead',
  'Greateguren',
  'Griffonomed',
  'Handcapod',
  'HaroMajere',
  'Heheadcobb',
  'Himestn',
  'Hunterhead',
  'Incartleze',
  'Inordity',
  'Issuestre',
  'KaiBrace',
  'Konristi',
  'LessPurfect',
  'LightFred',
  'LimeWeirdHello',
  'LouAnime',
  'Lumecher',
  'MaidCheeNaybor',
  'MajorYui',
  'Mantecklena',
  'Masterield',
  'Mixalys',
  'Natucsco',
  'NeoRozMonkey',
  'Nomyoknu',
  'Nuwerclo',
  'Parance',
  'Phobicatic',
  'Piercessor',
  'Primirier',
  'Pruntson',
  'RadiantXglossy',
  'Recomlepa',
  'Rhemadou',
  'Rundping',
  'SandWriting',
  'SchoolAlli',
  'ScoobyVital',
  'Shinginket',
  'Smarthweb',
  'SocialSmarter',
  'StahMrFeature',
  'Sweettems',
  'Swingbigb',
  'Talentedia',
  'Tasoftie',
  'ThehibikiLunatic',
  'ThenornDancer',
  'ThenornKitGorgeous',
  'Trendynetc',
  'TrimbleBee',
  'TrippinTins',
  'Undervera',
  'Uoutschumb',
  'VanderMiracle',
  'Waredusk',
  'Wellbeyer',
];

module.exports = usernames;
