module.exports = {
  card: {
    minHeight: '400px',
  },
  timingModal: {
    maxWidth: '575px',
  },
  commandText: {
    fontSize: '28px',
  },
  formText: {
    color: '#212529',
  },
};
